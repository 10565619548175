import React, { useEffect, useRef } from 'react';

interface AnnotationBoxProps {
  userId: string;
  username: string;
  date: string | Date;
  comment: string;
  onReply: () => void;
  onClose: () => void; // Function to hide the box on lost focus
}

const AnnotationBox: React.FC<AnnotationBoxProps> = ({ userId, username, date, comment, onReply, onClose }) => {
  const boxRef = useRef<HTMLDivElement>(null);

  // Handle clicking outside the box to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        onClose(); // Call onClose when clicking outside the annotation box
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const avatarUrl = `https://mostlyhuman-public.s3.amazonaws.com/avatars-${userId}`;

  return (
    <div
      className="Annotation__box"
      ref={boxRef}
      style={{
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)', // Center the box
        position: 'fixed', // Fixed to ensure it stays in view while scrolling
        zIndex: 1000, // Ensure it's on top of other elements
      }}
    >
      <div className="flex items-center mb-2">
        <img src={avatarUrl} alt="Avatar" className="mr-4 w-12 h-12 rounded-full bg-color10" />
        <p>
          <strong>{username}</strong> said on <em>{new Date(date).toLocaleDateString()}</em>:
        </p>
      </div>
      <blockquote>{comment}</blockquote>
      <button onClick={onReply} className="Annotation__reply-button">Reply</button>
    </div>
  );
};

export default AnnotationBox;

import React from 'react';
import { useFormContext } from '../form-wrapper';

interface TextInputProps {
    name: string;
    placeholder?: string;
    type?: string;
}

export const TextInput: React.FC<TextInputProps> = ({ name, placeholder, type = 'text' }) => {
    const { formData, setFormData } = useFormContext();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFormData = new FormData();
        // Copy existing entries to new FormData
        formData.forEach((value, key) => newFormData.append(key, value));
        newFormData.set(name, e.target.value);
        setFormData(newFormData);
    };

    const value = formData.get(name) as FormDataEntryValue;
    const inputValue = typeof value === 'string' ? value : '';

    return (
        <input
            type={type}
            name={name}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleChange}
            className="p-2 my-1 block border border-gray-300 rounded"
        />
    );
};

import React from 'react';
import ChatWindow from './components/chat-window';
import './index.css'; // Make sure this includes Tailwind's base styles
import './components/old-profile.css'; // Import custom styles

const App: React.FC = () => {
    return (
        <div className="h-screen flex justify-center items-center">
            <div className="w-full max-w-3xl h-full">
                <div className=" bg-gradient-to-r from-lightSteelBlue to-lightSteelBlueLight">
                    <ChatWindow />
                </div>
            </div>
        </div>
    );
};

export default App;

import React, { useState } from 'react';
import { apiCall } from '../../utils/api';
import Spinner from '../../utils/spinner';

interface LogoutPanelProps {
    onSuccess: () => void;
}

const LogoutPanel: React.FC<LogoutPanelProps> = ({ onSuccess }) => {
    const [logoutStatus, setLogoutStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

    const handleLogout = async () => {
        try {
            setLogoutStatus('loading');
            const result = await apiCall('/logout', {});
            onSuccess();
            console.log('Logout successful:', result);
            // Delete the access_token cookie
            document.cookie = 'access_token=; Max-Age=0; path=/; domain=' + window.location.hostname + ';';
            setLogoutStatus('success');
            // Redirect to the login page or home page after a short delay
            setTimeout(() => {
                window.location.href = '/login';
            }, 1500);
        } catch (error) {
            console.error('Logout failed:', error);
            setLogoutStatus('error');
        }
    };

    return (
        <div className="logout-panel text-color12">
            {logoutStatus === 'idle' && (
                <button onClick={handleLogout} className="btn-logout">Log Out</button>
            )}
            {logoutStatus === 'loading' && <Spinner message="Logging out..." />}
            {logoutStatus === 'success' && <p>You have been logged out successfully.</p>}
            {logoutStatus === 'error' && <p>Logout failed. Please try again.</p>}
        </div>
    );
};

export default LogoutPanel;

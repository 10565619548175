import React from 'react';

interface UserPanelProps {
    uuid: string;
    userUuid: string;
    message: string;
    originalLanguage: string;
    status: string;
    onSetContext: (context: string) => void;
}

const UserPanel: React.FC<UserPanelProps> = ({
    uuid,
    userUuid,
    message,
    originalLanguage: language,
    status,
    onSetContext,
}) => {

    return (
        <div className="user-reply-panel text-color12">
            <p>{message}</p>
        </div>
    );
};

export default UserPanel;

import React from 'react';

interface AssistantPanelProps {
    uuid: string;
    userUuid: string;
    message: string;
    originalLanguage: string;
    status: string;
    onSetContext: (context: string) => void;
}

const AssistantPanel: React.FC<AssistantPanelProps> = ({
    uuid,
    userUuid,
    message,
    originalLanguage: language,
    status,
    onSetContext,
}) => {

    return (
        <div className="assistant-reply-panel text-color12">
            <p>{message}</p>
        </div>
    );
};

export default AssistantPanel;

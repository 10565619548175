// utils/api.ts

const defaultHost = 'https://api.mostlyhuman.io';

// Function for sending and receiving JSON data
const apiCall = async (endpoint: string, data: any = null, method: string = 'POST') => {
    console.log(`end-point: ${endpoint}`);

    const url = endpoint.startsWith('https') ? endpoint : `${defaultHost}${endpoint}`;

    const options: RequestInit = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'  // Include credentials (cookies) with requests
    };

    if (data && method === 'POST') {
        options.body = JSON.stringify(data);
    }

    try {
        const response = await fetch(url, options);

        if (!response.ok) {
            if (endpoint !== '/users/me') {
                throw new Error('Network response was not ok');
            } else {
                return { "status": "not logged in" }
            }
        }

        return response.json();
    } catch (e) {
        if (endpoint !== '/users/me') {
            throw new Error('Network response was not ok');
        } else {
            return { "status": "not logged in" }
        }
    }
};

// Function for sending form data
const apiFormCall = async (endpoint: string, formData: FormData) => {
    const url = endpoint.startsWith('https') ? endpoint : `${defaultHost}${endpoint}`;

    const response = await fetch(url, {
        method: 'POST',
        body: formData,
        credentials: 'include'
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return response.json();
};


export { apiCall, apiFormCall };

import React, { useState } from 'react';
import { TextInput } from '../supported-elements/text-input';
import { FormWrapper } from '../form-wrapper';
import { apiFormCall } from '../../utils/api';  // Use apiCall for JSON data
import Spinner from '../../utils/spinner';

const RegisterPanel: React.FC = () => {
    const [registerStatus, setRegisterStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

    const handleRegister = async (formData: Record<string, any>) => {
        try {
            setRegisterStatus('loading');
            const result = await apiFormCall('/register', formData as FormData);  // Use apiCall to send JSON data
            console.log('Register successful:', result);
            setRegisterStatus('success');
        } catch (error) {
            console.error('Register failed:', error);
            setRegisterStatus('error');
        }
    };

    return (
        <div className="text-color12">
            {registerStatus === 'idle' && (
                <FormWrapper onSubmit={handleRegister} submitButtonLabel="Register">
                    <div>
                        <p>Create a new account:</p>
                        <TextInput name="username" placeholder="Username" />
                        <TextInput type="email" name="email" placeholder="Email" />
                        <TextInput type="password" name="password" placeholder="Password" />
                        <TextInput type="password" name="confirmpassword" placeholder="Confirm Password" />
                    </div>
                </FormWrapper>
            )}
            {registerStatus === 'loading' && <Spinner message="Registering..." />}
            {registerStatus === 'success' && <p>Registration successful. You can now log in.</p>}
            {registerStatus === 'error' && <p>Registration failed. Please try again.</p>}
        </div>
    );
};

export default RegisterPanel;

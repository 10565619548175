// src/components/supported-elements/pagination-control.tsx
import React, { useState } from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

interface PaginationControlProps {
    gotoPage: (page: number) => void;
    onPrev: () => void;
    onNext: () => void;
    pageNumber: number;
    pageCount: number;
}

export const PaginationControl: React.FC<PaginationControlProps> = ({ gotoPage, onPrev, onNext, pageNumber, pageCount }) => {
    const [inputValue, setInputValue] = useState<string>(pageNumber.toString());
    const [currentPage, setCurrentPage] = useState((pageNumber > 0 && pageNumber <= pageCount) ? pageNumber : 1);

    const handlePageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const pageNum = parseInt(value, 10);

        if (!isNaN(pageNum) && pageNum > 0 && pageNum <= pageCount) {
            setInputValue(value);
        } else {
            setInputValue('');
        }
    };

    const handlePageInputBlur = () => {
        const pageNum = parseInt(inputValue, 10);
        if (!isNaN(pageNum) && pageNum > 0 && pageNum <= pageCount) {
            gotoPage(pageNum);
            setCurrentPage(pageNum);
        } else {
            setInputValue(currentPage.toString());
        }
    };

    return (
        <div className="flex items-center justify-center space-x-2">
            <button
                className={`text-gray-500 ${currentPage <= 1 ? 'cursor-not-allowed opacity-50' : 'hover:text-black'}`}
                disabled={currentPage <= 1}
                onClick={() => { gotoPage(1); setCurrentPage(1); setInputValue("1"); }}
            >
                <FaAngleDoubleLeft size={24} />
            </button>
            <button
                className={`text-gray-500 ${currentPage <= 1 ? 'cursor-not-allowed opacity-50' : 'hover:text-black'}`}
                disabled={currentPage <= 1}
                onClick={() => { onPrev(); setCurrentPage(currentPage - 1); setInputValue(`${currentPage - 1}`); }}
            >
                <FaAngleLeft size={24} />
            </button>
            <div className="flex items-center space-x-2">
                <span className="text-gray-600">Page</span>
                <input
                    type="text"
                    value={inputValue}
                    onChange={handlePageInputChange}
                    onBlur={handlePageInputBlur}
                    className="w-12 text-center border rounded border-gray-300"
                />
                <span className="text-gray-600">of {pageCount}</span>
            </div>
            <button
                className={`text-gray-500 ${currentPage >= pageCount ? 'cursor-not-allowed opacity-50' : 'hover:text-black'}`}
                disabled={currentPage >= pageCount}
                onClick={() => { onNext(); setCurrentPage(currentPage + 1); setInputValue(`${currentPage + 1}`); }}
            >
                <FaAngleRight size={24} />
            </button>
            <button
                className={`text-gray-500 ${currentPage >= pageCount ? 'cursor-not-allowed opacity-50' : 'hover:text-black'}`}
                disabled={currentPage >= pageCount}
                onClick={() => { gotoPage(pageCount); setCurrentPage(pageCount); setInputValue(`${pageCount}`); }}
            >
                <FaAngleDoubleRight size={24} />
            </button>
        </div>
    );
};

// src/components/supported-elements/detail-separator.tsx
import React from 'react';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';

interface DetailSeparatorProps {
    isReplyTo: boolean;
    repliesCount: number;
}

export const DetailSeparator: React.FC<DetailSeparatorProps> = ({ isReplyTo, repliesCount }) => {
    return <div className="text-slate-500">
             {isReplyTo ? 
                 <div className="flex flex-start items-center"><FaCaretUp className="ml-2 mr-4" size={28}/><span className="text-sm px-12">In Reply To</span><FaCaretUp className="ml-4 mr-2" size={28}/></div> : 
                 <div className="flex flex-start items-center"><FaCaretDown className="ml-2 mr-4" size={28}/><span className="text-sm px-12">{repliesCount} Replies</span><FaCaretDown className="ml-4 mr-2" size={28}/></div>
             }
           </div>;
};

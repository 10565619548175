import React, { Component } from "react";
import "./tip.css";

interface State {
  compact: boolean;
  showMenu: boolean; // Controls the floating menu
  text: string;
  emoji: string;
}

interface Props {
  onConfirm: (comment: { text: string; emoji: string }) => void;
  onOpen: () => void;
  onUpdate?: () => void;
}

export class Tip extends Component<Props, State> {
  state: State = {
    compact: true,
    showMenu: true, // Initially show the floating menu
    text: "",
    emoji: "",
  };

  // Reference to the tooltip menu
  tooltipRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    // Add event listener to detect clicks outside the tooltip
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove event listener when the component is unmounted
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    // Check if the click occurred outside the tooltip
    if (this.tooltipRef.current && !this.tooltipRef.current.contains(event.target as Node)) {
      this.setState({ showMenu: false });
    }
  };

  componentDidUpdate(_nextProps: Props, nextState: State) {
    const { onUpdate } = this.props;

    if (onUpdate && this.state.compact !== nextState.compact) {
      onUpdate();
    }
  }

  render() {
    const { onConfirm, onOpen } = this.props;
    const { compact, showMenu, text, emoji } = this.state;

    return (
      <div className="Tip max-w-72" ref={this.tooltipRef}>
        {showMenu ? ( // Display the floating menu first
          <div className="Tip__menu">
            <button
              className="Tip__menu-option"
              onClick={() => {
                // Handle save highlight logic
                this.setState({ showMenu: false });
                console.log("Save highlight option selected");
                // Add logic here to save the highlight if needed
              }}
            >
              Save Highlight
            </button>
            <button
              className="Tip__menu-option"
              onClick={() => {
                // Handle make annotation logic
                this.setState({ compact: false, showMenu: false });
                onOpen();
              }}
            >
              Make Annotation
            </button>
            <button
              className="Tip__menu-option"
              onClick={() => {
                // Handle send AI chat message logic
                this.setState({ showMenu: false });
                console.log("Send AI Chat message option selected");
                // Add logic here to send a message to the conversational UI
              }}
            >
              Send AI Chat Message
            </button>
            <button
              className="Tip__menu-option"
              onClick={() => {
                // Handle cancel logic
                this.setState({ showMenu: false });
                console.log("Cancel option selected");
              }}
            >
              Cancel
            </button>
          </div>
        ) : compact ? (
          <div
            className="Tip__compact"
            style={{ display: "none" }}
            onClick={() => {
              this.setState({ showMenu: true }); // Show the floating menu again
            }}
          >
            Add highlight
          </div>
        ) : (
          <form
            className="Tip__card"
            onSubmit={(event) => {
              event.preventDefault();
              onConfirm({ text, emoji });
            }}
          >
            <div>
              <textarea
                placeholder="Your comment"
                autoFocus
                value={text}
                onChange={(event) =>
                  this.setState({ text: event.target.value })
                }
                ref={(node) => {
                  if (node) {
                    node.focus();
                  }
                }}
                className="max-w-64"
              />
              {false && (
                <div>
                  {["😱", "😍", "🔥", "😳", "⚠️"].map((_emoji) => (
                    <label key={_emoji}>
                      <input
                        checked={emoji === _emoji}
                        type="radio"
                        name="emoji"
                        value={_emoji}
                        onChange={(event) =>
                          this.setState({ emoji: event.target.value })
                        }
                      />
                      {_emoji}
                    </label>
                  ))}
                </div>
              )}
            </div>
            <div>
              <input type="submit" value="Save" />
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default Tip;

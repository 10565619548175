import React, { createContext, useContext, useState } from 'react';
import { Button } from './supported-elements/button';

interface FormContextProps {
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const FormContext = createContext<FormContextProps | undefined>(undefined);

interface FormWrapperProps {
    children: React.ReactNode;
    onSubmit: (formData: FormData) => void;
    submitButtonLabel?: string;
}

export const FormWrapper: React.FC<FormWrapperProps> = ({ children, onSubmit, submitButtonLabel = 'Submit' }) => {
    const [formData, setFormData] = useState<FormData>(new FormData());

    const handleSubmit = () => {
        onSubmit(formData);
    };

    return (
        <FormContext.Provider value={{ formData, setFormData }}>
            <form
                onSubmit={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    handleSubmit(); // Call the wrapped handleSubmit function
                }}
            >
                {children}
                <Button label={submitButtonLabel} onClick={handleSubmit} />
            </form>
        </FormContext.Provider>
    );
};

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error('useFormContext must be used within a FormWrapper');
    }
    return context;
};

import React, { useState, useRef } from 'react';
import { FaMicrophone, FaPaperPlane } from 'react-icons/fa';

interface ChatInputProps {
    onSendMessage: (message: string) => void;
    onSendAudio: (audioFile: File) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, onSendAudio }) => {
    const [inputValue, setInputValue] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleSend = () => {
        if (inputValue.trim()) {
            onSendMessage(inputValue);
            setInputValue('');
        }
    };

    const startRecording = async () => {
        try {
            console.log(`we start recording`)
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };
            recorder.onstop = () => {
                console.log(`we need to start recording now`)
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
                const audioFile = new File([audioBlob], 'recording.mp3', { type: 'audio/mp3' });
                console.log(`invoking onSendAudio... audioFile: ${audioFile}`)
                onSendAudio(audioFile);
                audioChunksRef.current = [];
            };
            recorder.start();
            setMediaRecorder(recorder);
            setIsRecording(true);
        } catch (error) {
            console.error('Failed to start recording:', error);
        }
    };

    const stopRecording = () => {
        console.log(`stopRecording invoked`)
        if (mediaRecorder) {
            console.log(`stopping the recording`)
            mediaRecorder.stop();
            console.log(`the recording has been stopped`)
            setIsRecording(false);
        }
    };

    return (
        <div className="flex items-center gap-2">
            <button
                className={`p-2 rounded-full text-white ${isRecording ? 'bg-red-500' : 'bg-color11'}`}
                onClick={isRecording ? stopRecording : startRecording}
            >
                <FaMicrophone />
            </button>
            <input
                type="text"
                className="flex-grow p-2 border border-gray-300 rounded"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Click a message first if replying to it here"
            />
            <button onClick={handleSend} className="p-2 bg-color11 text-white rounded-full">
                <FaPaperPlane />
            </button>
        </div>
    );
};

export default ChatInput;

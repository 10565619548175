import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FaHome, FaSearch, FaList, FaSignOutAlt, FaSignInAlt, FaBookReader } from 'react-icons/fa';
import ChatInput from './chat-input';
import MessageBubble from './message-bubble';
import LoginPanel from './auth/login';
import RegisterPanel from './auth/register';

import logo from '../assets/logo.png';
import { apiCall, apiFormCall } from '../utils/api';
import LogoutPanel from './auth/logout';
import InformationalPanel from './supported-elements/informational';
import SurveyPanel from './supported-elements/survey';
import DiscussionPanel from './supported-elements/discussion';
import AssistantPanel from './supported-elements/assistant';
import UserPanel from './supported-elements/user';
import CommentPanel from './supported-elements/comment';
//import ProfilePanel from './supported-elements/profile';
import Spinner from '../utils/spinner';
import SearchResultPanel from './supported-elements/search-result';
import { DetailSeparator } from './supported-elements/detail-separator';
import { PaginationControl } from './supported-elements/pagination-control';
import { AvatarControl } from './supported-elements/avatar-control';
import PDFViewer from './pdf-viewer';

interface Message {
    content: React.ReactNode;
    isUser: boolean;
    noClick?: boolean;
    avatar?: string | null; // Updated to allow `string`, `null`, or `undefined`
    uuid?: string | null;
}

const ChatWindow: React.FC = () => {
    const messagesStartRef = useRef<HTMLDivElement | null>(null);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const welcomeMessage = `
Welcome to Mostly Human! Poly, your Personal Knowledge Agent, at your service. As you may know, Mostly Human is a unique platform where you can connect with others, share knowledge, and learn from a diverse community. I'm here to guide you.
What brings you to Mostly Human today?
    `;
    const emptyResultsPaneMessage = `This is where your search results appear.`
    const emptyDetailsPaneMessage = `This is where your result details appear after selecting a search result.`

    const scrollToTop = () => {
        messagesStartRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [messages, setMessages] = useState<Message[]>([
        { content: welcomeMessage, isUser: false, avatar: logo }
    ]);

    const [homePaneMessages, setHomePaneMessages] = useState<Message[]>([]);
    const [resultsPaneMessages, setResultsPaneMessages] = useState<Message[]>([
        { content: emptyResultsPaneMessage, isUser: false, avatar: logo }
    ]);
    const [detailsPaneMessages, setDetailsPaneMessages] = useState<Message[]>([
        { content: emptyDetailsPaneMessage, isUser: false, avatar: logo }
    ]);
    const [activePane, setActivePane] = useState('home');

    const [activeMessageIndex, setActiveMessageIndex] = useState<number | null>(null);
    const [context, setContext] = useState<string | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [userId, setUserId] = useState('');
    const [isFetching, setIsFetching] = useState<boolean>(false);  // Track fetching state
    const messagesLengthRef = useRef(messages.length);
    const [hasFetchedAfterLogin, setHasFetchedAfterLogin] = useState<boolean>(false);
    const [isPdfMode, setIsPdfMode] = useState(false);
    //const docUrl = useState('https://arxiv.org/pdf/2403.17101.pdf');
    const [docUrl, setDocUrl] = useState('https://arxiv.org/pdf/2307.00908.pdf');
    const [isUrlDialogOpen, setIsUrlDialogOpen] = useState(false);
    const [inputUrl, setInputUrl] = useState(docUrl);

    // UseEffect to update messages length ref whenever messages change
    useEffect(() => {
        messagesLengthRef.current = messages.length;
    }, [messages]);

    /*
    const defaultAvatar = FaUserAlt.toString();

    const loadAvatar = async (user_uuid: string): Promise<string> => {
        const imageUrl = 'https://mostlyhuman-public.s3.amazonaws.com/avatars-' + user_uuid;
        try {
            const imageResponse = await fetch(imageUrl);
            if (!imageResponse.ok) {
                console.error('Failed to fetch avatar:');
                return logo; // defaultAvatar;
            }
            const imageData = await imageResponse.blob();
            const base64String = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as string);
                reader.readAsDataURL(imageData);
            });
            return base64String as string;
        } catch (error) {
            console.error('Failed to load avatar:', error);
            return defaultAvatar;
        }
    }
    */

    const logState = useCallback((msg: string) => {
        const simplifiedMessages = messages.map(msg => ({
            isUser: msg.isUser,
            avatar: msg.avatar,
            uuid: msg.uuid,
            noClick: msg.noClick,
            contentType: typeof msg.content === 'string' ? 'text' : 'react-element'
        }));

        const state = {
            messages: simplifiedMessages,
            activeMessageIndex,
            context
        };

        console.log(msg);
        console.log('chat window state:', state);
    }, [messages, activeMessageIndex, context]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const switchActivePane = (pane: string) => {
        if (isFetching || pane === activePane) return;  // Prevent switching if fetching or already on the pane
        switch (activePane) {
            case 'home':
                setHomePaneMessages(messages);
                break;
            case 'results':
                setResultsPaneMessages(messages);
                break;
            case 'details':
                setDetailsPaneMessages(messages);
                break;
        }
        switch (pane) {
            case 'home':
                setActivePane(pane);
                setMessages(homePaneMessages);
                break;
            case 'results':
                setActivePane(pane);
                setMessages(resultsPaneMessages);
                break;
            case 'details':
                setActivePane(pane);
                setMessages(detailsPaneMessages);
                break;
        }
    }

    const fetchUserIntents: () => Promise<void> = useCallback(async () => {
        logState("ENTER fetchUserIntents");
        setIsFetching(true);  // Set fetching state to true
        try {
            const response = await apiCall('/user-intents', null, 'GET');
            const intents = response.intents;
            if (intents.length > 0) {
                setMessages([
                    { content: <AvatarControl />, isUser: false, avatar: logo }/*,
                    { content: <ProfilePanel />, isUser: false, avatar: logo }*/
                ]);
            } else {
                setMessages([
                    { content: welcomeMessage, isUser: false, avatar: logo },
                    { content: <AvatarControl />, isUser: false }/*,
                    { content: <ProfilePanel />, isUser: false, avatar: logo }*/
                ]);
            }

            const newMessages = intents.map((intent: any) => {
                const action = intent.action;
                console.log(`action=${action}`);
                switch (action) {
                    case "assistant":
                        return {
                            content: (
                                <AssistantPanel
                                    uuid={intent.uuid}
                                    userUuid={intent.user_uuid}
                                    message={intent.message}
                                    originalLanguage={intent.original_language}
                                    status={intent.status}
                                    onSetContext={setContext}
                                />
                            ),
                            isUser: false,
                            avatar: logo,
                            uuid: intent.uuid
                        };
                    case "user":
                        return {
                            content: (
                                <UserPanel
                                    uuid={intent.uuid}
                                    userUuid={intent.user_uuid}
                                    message={intent.message}
                                    originalLanguage={intent.original_language}
                                    status={intent.status}
                                    onSetContext={setContext}
                                />
                            ),
                            isUser: true,
                            avatar: 'https://mostlyhuman-public.s3.amazonaws.com/avatars-' + intent.user_uuid,
                            uuid: intent.uuid
                        };
                    case "comment":
                        return {
                            content: (
                                <CommentPanel
                                    uuid={intent.uuid}
                                    userUuid={intent.user_uuid}
                                    username={intent.username}
                                    comment={intent.comment}
                                    originalLanguage={intent.original_language}
                                    commentCount={intent.comment_count}
                                    status={intent.status}
                                    createdAt={intent.created_at}
                                    onSetContext={setContext}
                                />
                            ),
                            isUser: true,
                            avatar: 'https://mostlyhuman-public.s3.amazonaws.com/avatars-' + intent.user_uuid,
                            uuid: intent.uuid
                        };
                    case "informational":
                        return {
                            content: (
                                <InformationalPanel
                                    uuid={intent.uuid}
                                    userUuid={intent.user_uuid}
                                    query={intent.query}
                                    answer={intent.answer}
                                    queryLanguage={intent.language}
                                    hashtags={intent.hash_tags}
                                    commentCount={intent.comment_count}
                                    accessConstraints={intent.access_constraints}
                                    status={intent.status}
                                    onReply={(uuid) => setActiveMessageIndex(messagesLengthRef.current)}
                                    onSetContext={setContext}
                                    onShared={fetchUserIntents}
                                />
                            ),
                            isUser: false,
                            avatar: logo,
                            uuid: intent.uuid
                        };
                    case "survey":
                        const userAnswer = intent.userAnswer ? intent.userAnswer : null;
                        return {
                            content: (
                                <SurveyPanel
                                    uuid={intent.uuid}
                                    userUuid={intent.user_uuid}
                                    prompt={intent.prompt}
                                    language={intent.original_language}
                                    choices={intent.choices}
                                    freetext={intent.free_text}
                                    rating={intent.rating}
                                    ranking={intent.ranking}
                                    allowMultipleChoice={intent.allow_multiple_choice}
                                    hashtags={intent.hash_tags}
                                    commentCount={intent.comment_count}
                                    accessConstraints={intent.access_constraints}
                                    status={intent.status}
                                    userAnswer={userAnswer} // Pass the user's answer if available
                                    onReply={(uuid: string) => setActiveMessageIndex(messagesLengthRef.current)}
                                    onSetContext={setContext}
                                    onShared={fetchUserIntents}
                                />
                            ),
                            isUser: false,
                            avatar: logo,
                            uuid: intent.uuid
                        };
                    case "discussion":
                        return {
                            content: (
                                <DiscussionPanel
                                    uuid={intent.uuid}
                                    userUuid={intent.user_uuid}
                                    topic={intent.topic}
                                    commentCount={intent.comment_count}
                                    originalLanguage={intent.language}
                                    hashtags={intent.hash_tags}
                                    accessConstraints={intent.access_constraints}
                                    status={intent.status}
                                    onReply={(uuid: string) => setActiveMessageIndex(messagesLengthRef.current)}
                                    onSetContext={setContext}
                                    onShared={fetchUserIntents}
                                />
                            ),
                            isUser: false,
                            avatar: logo,
                            uuid: intent.uuid
                        };
                    default:
                        return {
                            content: `Unknown action: ${action}`,
                            isUser: false,
                            avatar: logo,
                            uuid: intent.uuid
                        };
                }
            });

            setMessages(prevMessages => [...prevMessages, ...newMessages]);
            setIsFetching(false);
            logState("EXIT fetchUserIntents");
        } catch (error: unknown) {
            if (error instanceof Error && error.message.includes('401')) {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { content: <LoginPanel onSuccess={() => { fetchUserIntents(); setIsLoggedIn(true); }} />, isUser: false, avatar: logo }
                ]);
            } else {
                console.error('Error fetching user intents:', error);
            }
            setIsFetching(false);
        }
    }, [logState, welcomeMessage]);

    /*
    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const response = await apiCall('/users/me', null, 'GET');
                if (response.uuid) {
                    setIsLoggedIn(true);
                    setUserId(response.uuid);
                    fetchUserIntents();
                } else {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                setIsLoggedIn(false);
            }
        };

        checkLoginStatus();
    }, []);
    */

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const response = await apiCall('/users/me', null, 'GET');
                if (response.uuid) {
                    setIsLoggedIn(true);
                    setUserId(response.uuid);

                    // Fetch intents only if the user is logged in and not fetching
                    if (!isFetching && !hasFetchedAfterLogin) {
                        await fetchUserIntents();
                        setHasFetchedAfterLogin(true);
                    }
                } else {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                setIsLoggedIn(false);
            }
        };

        checkLoginStatus();
    }, [fetchUserIntents, isFetching, hasFetchedAfterLogin]); // Keep `fetchUserIntents` in the dependencies array

    const showLogin = () => {
        setMessages((prevMessages) => [
            ...prevMessages,
            { content: <LoginPanel onSuccess={() => { fetchUserIntents(); setIsLoggedIn(true); }} />, isUser: false, avatar: logo, noClick: true }
        ]);
    }

    const showLogout = () => {
        setMessages((prevMessages) => [
            ...prevMessages,
            { content: <LogoutPanel onSuccess={() => { setIsLoggedIn(false); }} />, isUser: false, avatar: logo, noClick: true }
        ]);
    }

    const handleSendAudio = async (audioFile: File) => {
        logState("ENTER handleSendAudio");
        try {
            console.log(`handleSendAudio has been called`)
            const formData = new FormData();
            formData.append('file', audioFile);

            // Send the audio file to the convert-audio endpoint
            const response = await apiFormCall('/convert-audio', formData);
            console.log('Audio converted successfully:', response);

            // Use the transcribed text for further processing
            const transcribedText = response.transcribed_text;
            handleSendMessage(transcribedText);
        } catch (error) {
            console.error('Error uploading audio:', error);
        }
        logState("EXIT handleSendAudio");
    };

    const onSearchResultSelected = (uuid: string, content: string, type: string) => {
        console.log(`onSearchResultSelected --> uuid: ${uuid}; content: ${content}; type: ${type}`)
    }

    const loadItemWithComments = async (
        subjectUuid: string,
        page: number
    ) => {
        try {
            switch (activePane) {
                case 'home':
                    setHomePaneMessages(messages);
                    break;
                case 'results':
                    setResultsPaneMessages(messages);
                    break;
                case 'details':
                    setDetailsPaneMessages(messages);
                    break;
            }
            setActivePane('details');

            // Fetch the replied node from the backend
            let repliedNodeResponse = null

            // Fetch comments from the backend
            const response = await apiCall('/node-with-comments', { subject_uuid: subjectUuid, page });

            if (!response) {
                console.error("Failed to load comments");
                return;
            }

            console.log(`/node-with-comments --> results: ${JSON.stringify(response)}`);

            const { comments_data, total_count, page_size } = response;

            // Search for the selected message in the current messages list
            const selectedMessage = messages.find(msg => msg.uuid === subjectUuid);
            let selectedItemComponent: React.ReactNode;
            console.log(`loadItemWithComments --> selectedMessage: ${JSON.stringify(selectedMessage)}`)

            if (selectedMessage) {
                selectedItemComponent = selectedMessage.content;
            } else {
                console.error(`Message with uuid ${subjectUuid} not found`);
                return;
            }

            if (selectedMessage && React.isValidElement(selectedMessage.content)) {
                if (selectedMessage.content.type === CommentPanel) {
                    repliedNodeResponse = await apiCall('/replied-node', { comment_uuid: subjectUuid });
                    console.log(`repliedNodeResponse: ${JSON.stringify(repliedNodeResponse)}`)
                }
            }

            const newMessages: Message[] = []

            // Build the list of messages to display
            if (repliedNodeResponse) {
                const { entity_type, ...repliedNode } = repliedNodeResponse;
                let repliedNodeComponent: React.ReactNode | null;
                console.log(`entity_type: ${entity_type}`);

                // Customize how to display the replied node based on its type
                switch (entity_type) {
                    case 'informational':
                    case 'InformationalQuestion':
                        repliedNodeComponent = (
                            <InformationalPanel
                                uuid={repliedNode.uuid}
                                userUuid={repliedNode.user_uuid}
                                query={repliedNode.query}
                                answer={repliedNode.answer}
                                queryLanguage={repliedNode.language}
                                hashtags={repliedNode.hash_tags}
                                commentCount={repliedNode.comment_count}
                                accessConstraints={repliedNode.access_constraints}
                                status='shared' // {repliedNode.status}
                                onReply={(uuid) => setActiveMessageIndex(messages.length)}
                                onSetContext={setContext}
                                onShared={fetchUserIntents}
                            />
                        );
                        break;
                    case 'survey':
                    case 'SurveyQuestion':
                        const userAnswer = repliedNode.userAnswer ? repliedNode.userAnswer : null;
                        repliedNodeComponent = (
                            <SurveyPanel
                                uuid={repliedNode.uuid}
                                userUuid={repliedNode.user_uuid}
                                prompt={repliedNode.prompt}
                                language={repliedNode.original_language}
                                choices={repliedNode.choices}
                                freetext={repliedNode.free_text}
                                rating={repliedNode.rating}
                                ranking={repliedNode.ranking}
                                allowMultipleChoice={repliedNode.allow_multiple_choice}
                                hashtags={repliedNode.hash_tags}
                                commentCount={repliedNode.comment_count}
                                accessConstraints={repliedNode.access_constraints}
                                status='shared' // {repliedNode.status}
                                userAnswer={userAnswer} // Assuming the backend includes user_answer in the result
                                onReply={(uuid: string) => setActiveMessageIndex(newMessages.length)}
                                onSetContext={setContext}
                                onShared={fetchUserIntents}
                            />
                        );
                        break;
                    case 'discussion':
                        repliedNodeComponent = (
                            <DiscussionPanel
                                uuid={repliedNode.uuid}
                                userUuid={repliedNode.user_uuid}
                                topic={repliedNode.topic}
                                commentCount={repliedNode.comment_count}
                                originalLanguage={repliedNode.query_language}
                                hashtags={repliedNode.hash_tags}
                                accessConstraints={repliedNode.access_constraints}
                                status='shared' // {repliedNode.status}
                                onReply={(uuid: string) => setActiveMessageIndex(newMessages.length)}
                                onSetContext={setContext}
                                onShared={fetchUserIntents}
                            />
                        );
                        break;
                    case 'comment':
                        repliedNodeComponent = (
                            <CommentPanel
                                uuid={repliedNode.uuid}
                                userUuid={repliedNode.user_uuid}
                                username={repliedNode.username}
                                comment={repliedNode.comment}
                                originalLanguage={repliedNode.original_language}
                                commentCount={repliedNode.comment_count}
                                status={repliedNode.status}
                                createdAt={repliedNode.created_at}
                                onSetContext={setContext}
                            />
                        );
                        break;
                    default:
                        repliedNodeComponent = null;
                }

                console.log(`repliedNodeComponent: ${repliedNodeComponent}`)

                if (repliedNodeComponent !== null) {
                    //const avatarData : string = await loadAvatar(repliedNode.user_uuid)
                    //console.log(`we are here`)
                    newMessages.push(
                        { content: repliedNodeComponent, isUser: false, uuid: repliedNode.uuid, avatar: 'https://mostlyhuman-public.s3.amazonaws.com/avatars-' + repliedNode.user_uuid }
                    );
                    newMessages.push(
                        { content: <DetailSeparator isReplyTo={true} repliesCount={-1} />, isUser: false, noClick: true },
                    );
                }

            }

            newMessages.push({ content: selectedItemComponent, isUser: false }); // Display the selected item

            comments_data.forEach((commentData: { comment: { uuid: string; user_uuid: string; username: string; comment: string; original_language: string; comment_count: number; status: string; created_at: string; }; reply_count: number; }) => {
                console.log(`loadItemWithComments --> got one`)
                //const avatarData : string = await loadAvatar(commentData.comment.user_uuid)
                //console.log(`avatarData: ${avatarData}`)
                newMessages.push({
                    content: (
                        <CommentPanel
                            uuid={commentData.comment.uuid}
                            userUuid={commentData.comment.user_uuid}
                            username={commentData.comment.username}
                            comment={commentData.comment.comment}
                            originalLanguage={commentData.comment.original_language}
                            commentCount={commentData.comment.comment_count}  // Use reply_count to show the number of replies to this comment
                            status={commentData.comment.status}
                            createdAt={commentData.comment.created_at}
                            onSetContext={setContext}
                        />
                    ),
                    isUser: true,
                    avatar: 'https://mostlyhuman-public.s3.amazonaws.com/avatars-' + commentData.comment.user_uuid, // Adjust avatar handling as needed
                    uuid: commentData.comment.uuid
                });
            });

            if (total_count > 10) {
                newMessages.push({
                    content: (
                        <PaginationControl
                            gotoPage={(page) => loadItemWithComments(subjectUuid, page/*, setMessages, scrollToTop*/)}
                            onPrev={() => loadItemWithComments(subjectUuid, page - 1/*, setMessages, scrollToTop*/)}
                            onNext={() => loadItemWithComments(subjectUuid, page + 1/*, setMessages, scrollToTop*/)}
                            pageNumber={page}
                            pageCount={Math.ceil(total_count / page_size)}
                        />
                    ),
                    isUser: false
                });
            }

            // Set the new messages in state
            setMessages(newMessages);
            scrollToTop(); // Scroll to top after setting the messages
        } catch (error) {
            console.error("Error loading item with comments:", error);
        }
    };

    const handleSendMessage = async (message: string) => {
        logState("ENTER handleSendMessage");
        const newMessages = [...messages, { content: message, isUser: true, avatar: 'user-avatar.png' }];
        setMessages(newMessages);

        if (activePane === 'results') {
            const fetchSearchResults = async (page: number) => {
                const searchResponse = await apiCall('/search', { "query": message, "page": page });
                const { results, total_count, page: currentPage, page_size } = searchResponse;

                let newResults: any[] = [];
                results.forEach((result: any) => {
                    newResults.push({
                        content: <SearchResultPanel uuid={result.uuid} content={result.content} type={result.type} onSelect={onSearchResultSelected} />,
                        isUser: false,
                        avatar: logo,
                        uuid: result.uuid
                    });
                });

                const pageCount = total_count > 0 ? Math.ceil(total_count / page_size) : 0;
                console.log(`total count: ${total_count}; page count: ${pageCount}; page size: ${page_size}`)

                if (pageCount > 1) {
                    newResults.push({
                        content: <PaginationControl
                            gotoPage={function (page: number): void {
                                fetchSearchResults(page);
                            }}
                            onPrev={function (): void {
                                if (currentPage > 1) fetchSearchResults(currentPage - 1);
                            }}
                            onNext={function (): void {
                                if (currentPage < pageCount) fetchSearchResults(currentPage + 1);
                            }}
                            pageNumber={currentPage}
                            pageCount={pageCount} />,
                        isUser: false,
                        avatar: null
                    });
                }

                if (results.length < 1) {
                    setMessages([{ content: "No results found", isUser: false, avatar: logo }])
                } else {
                    setMessages(newResults)
                }
            };

            fetchSearchResults(1); // Start with the first page
            return;
        }

        if (context === 'forward' && activeMessageIndex !== null) {
            console.log(`message: ${message}`);
            console.log(`activeMessageIndex: ${activeMessageIndex}`);
            await handleForwardMessage(message, messages[activeMessageIndex!].uuid!);
            setContext(null);  // Reset context after handling forward
        } else {
            let app_context = "";
            let selected_message = "";
            if (activeMessageIndex !== null) {
                app_context = "selected-message";
                selected_message = messages[activeMessageIndex!].uuid!;
            }
            console.log(`selected-message? message: ${message}; activeMessageIndex: ${activeMessageIndex}; selected_message: ${selected_message}`)
            const prevMessages = [...messages];
            setMessages([...messages, { content: <div><Spinner /><span className="ml-2">One moment...</span></div>, isUser: false, avatar: logo }])
            const response = await apiCall('/process-input', { "user_input": message, app_context, selected_message });
            setMessages(prevMessages);
            console.log(`handleSendMessage: ${JSON.stringify(response)}`);
            setActiveMessageIndex(null);
            const result = response.result;

            if (result && result.action) {
                const action = result.action.replace("-", "");
                switch (action) {
                    case "signup":
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            { content: <RegisterPanel />, isUser: false, avatar: logo, noClick: true }
                        ]);
                        break;
                    case "login":
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            { content: <LoginPanel onSuccess={() => { fetchUserIntents(); setIsLoggedIn(true); }} />, isUser: false, avatar: logo, noClick: true }
                        ]);
                        break;
                    case "logout":
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            { content: <LogoutPanel onSuccess={() => { setIsLoggedIn(false); }} />, isUser: false, avatar: logo, noClick: true }
                        ]);
                        break;
                    case "assistant":
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            {
                                content: (
                                    <AssistantPanel
                                        uuid={result.uuid}
                                        userUuid={result.user_uuid}
                                        message={result.message}
                                        originalLanguage={result.original_language}
                                        status={result.status}
                                        onSetContext={setContext}
                                    />
                                ),
                                isUser: false,
                                avatar: logo,
                                uuid: result.uuid
                            }
                        ]);
                        fetchUserIntents();
                        break;
                    case "user":
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            {
                                content: (
                                    <UserPanel
                                        uuid={result.uuid}
                                        userUuid={result.user_uuid}
                                        message={result.message}
                                        originalLanguage={result.original_language}
                                        status={result.status}
                                        onSetContext={setContext}
                                    />
                                ),
                                isUser: true,
                                avatar: 'https://mostlyhuman-public.s3.amazonaws.com/avatars-' + result.user_uuid,
                                uuid: result.uuid
                            }
                        ]);
                        fetchUserIntents();
                        break;
                    case "comment":
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            {
                                content: (
                                    <CommentPanel
                                        uuid={result.uuid}
                                        userUuid={result.user_uuid}
                                        username={result.username}
                                        comment={result.comment}
                                        originalLanguage={result.original_language}
                                        commentCount={result.comment_count}
                                        status={result.status}
                                        createdAt={result.created_at}
                                        onSetContext={setContext}
                                    />
                                ),
                                isUser: true,
                                avatar: 'https://mostlyhuman-public.s3.amazonaws.com/avatars-' + result.user_uuid,
                                uuid: result.uuid
                            }
                        ]);
                        fetchUserIntents();
                        break;
                    case "informational":
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            {
                                content: (
                                    <InformationalPanel
                                        uuid={result.uuid}
                                        userUuid={result.user_uuid}
                                        query={result.query}
                                        answer={result.answer}
                                        queryLanguage={result.query_language}
                                        hashtags={result.hash_tags}
                                        commentCount={result.comment_count}
                                        accessConstraints={result.access_constraints}
                                        status={result.status}
                                        onReply={(uuid) => setActiveMessageIndex(newMessages.length)}
                                        onSetContext={setContext}
                                        onShared={fetchUserIntents}
                                    />
                                ),
                                isUser: false,
                                avatar: logo,
                                uuid: result.uuid
                            }
                        ]);
                        fetchUserIntents();
                        break;
                    case "survey":
                    case "survey-response":
                    case "answered-survey-question":
                        console.log(`survey: ${JSON.stringify(result)}`);
                        // Determine if the survey has been answered and retrieve the user's response if available
                        // const userAnswer = intent.userAnswer ? JSON.parse(intent.userAnswer) : null;
                        const userAnswer = result.userAnswer ? result.userAnswer : null;
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            {
                                content: (
                                    <SurveyPanel
                                        uuid={result.uuid}
                                        userUuid={result.user_uuid}
                                        prompt={result.prompt}
                                        language={result.original_language}
                                        choices={result.choices}
                                        freetext={result.free_text}
                                        rating={result.rating}
                                        ranking={result.ranking}
                                        allowMultipleChoice={result.allow_multiple_choice}
                                        hashtags={result.hash_tags}
                                        commentCount={result.comment_count}
                                        accessConstraints={result.access_constraints}
                                        status={result.status}
                                        userAnswer={userAnswer} // Assuming the backend includes user_answer in the result
                                        onReply={(uuid: string) => setActiveMessageIndex(newMessages.length)}
                                        onSetContext={setContext}
                                        onShared={fetchUserIntents}
                                    />
                                ),
                                isUser: false,
                                avatar: logo,
                                uuid: result.uuid
                            }
                        ]);
                        fetchUserIntents();
                        break;
                    case "discussion":
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            {
                                content: (
                                    <DiscussionPanel
                                        uuid={result.uuid}
                                        userUuid={result.user_uuid}
                                        topic={result.topic}
                                        commentCount={result.comment_count}
                                        originalLanguage={result.query_language}
                                        hashtags={result.hash_tags}
                                        accessConstraints={result.access_constraints}
                                        status={result.status}
                                        onReply={(uuid: string) => setActiveMessageIndex(newMessages.length)}
                                        onSetContext={setContext}
                                        onShared={fetchUserIntents}
                                    />
                                ),
                                isUser: false,
                                avatar: logo,
                                uuid: result.uuid
                            }
                        ]);
                        fetchUserIntents();
                        break;
                    default:
                        console.warn(`Unknown action: ${action}`);
                }
                scrollToBottom();
            } else {
                console.error('Action is undefined or result is not in the expected format');
            }
        }
        logState("EXIT handleSendMessage");
    };

    const handleForwardMessage = async (message: string, userIntentUuid: string) => {
        logState("ENTER handleForwardMessage");
        console.log(`handleForwardMessage --> message: ${message}; userIntentUuid: ${userIntentUuid}`)
        //const result = await apiCall('/share-informational-question', { user_intent_uuid: userIntentUuid, additional_input: message });
        //console.log('Forward successful:', result);
        logState("EXIT handleForwardMessage");
    };

    const onSelectMenuOption = (msg: Message, index: number, option: string) => {
        console.log(`onSelectMenuOption --> msg: ${JSON.stringify(msg)}; uuid: ${msg.uuid}`)
        if (!isFetching && msg.uuid) {
            console.log(`ready to load comments...`)
            loadItemWithComments(msg.uuid, 1/*, setMessages, scrollToTop*/);
        }
    }

    return (
        <>
            {isPdfMode &&
                <PDFViewer
                    initialUrl={docUrl}  // Pass in the document URL
                    userId={userId}
                    loadAnnotationView={(params: any) => { }}
                    exitAnnotationView={() => setIsPdfMode(false)}
                />
            }
            {!isPdfMode &&
                <div className="flex flex-col h-full min-h-screen p-4 bg-gradient-to-r from-lightSteelBlue to-lightSteelBlueLight items-center">
                    <div className="z-50 fixed top-0 w-full max-w-3xl px-4 py-1 bg-gradient-to-r from-lightSteelBlue to-lightSteelBlueLight text-color10">
                        <div className="flex justify-between items-center text-color10 mb-2">
                            <span className="font-extrabold text-lg">Mostly Human</span>
                            <div className="flex space-x-4">
                                {isLoggedIn && (
                                    <>
                                        <FaHome
                                            className={`cursor-pointer ${isFetching || activePane !== 'home' ? 'text-color5 hover:text-[#E0E0E0]' : 'text-color10'}`}
                                            size={21}
                                            onClick={() => switchActivePane('home')}
                                        />
                                        <FaList
                                            className={`cursor-pointer ${isFetching || activePane !== 'details' ? 'text-color5 hover:text-[#E0E0E0]' : 'text-color10'}`}
                                            size={21}
                                            onClick={() => switchActivePane('details')}
                                        />
                                        <FaSearch
                                            className={`cursor-pointer ${isFetching || activePane !== 'results' ? 'text-color5 hover:text-[#E0E0E0]' : 'text-color10'}`}
                                            size={21}
                                            onClick={() => switchActivePane('results')}
                                        />
                                        <FaBookReader
                                            className="cursor-pointer text-color5 hover:text-[#E0E0E0]"
                                            size={21}
                                            onClick={() => setIsUrlDialogOpen(true)}  // Open dialog on click
                                        />
                                    </>
                                )}
                                {!isLoggedIn && (
                                    <FaSignInAlt
                                        className="cursor-pointer text-color5 hover:text-[#E0E0E0]"
                                        size={21}
                                        onClick={showLogin}
                                    />
                                )}
                                {isLoggedIn && (
                                    <FaSignOutAlt
                                        className="cursor-pointer text-color5 hover:text-[#E0E0E0]"
                                        size={21}
                                        onClick={showLogout}
                                    />
                                )}
                            </div>
                        </div>
                        <hr className="h-1 bg-color7 border-0 rounded"></hr>
                    </div>
                    <div className="w-full max-w-3xl py-6 mb-6">
                        <div className="flex flex-col flex-1 overflow-y-auto mb-4">
                            <div ref={messagesStartRef}></div>
                            {messages.map((msg, index) => (
                                <MessageBubble
                                    key={index}
                                    message={msg.content}
                                    isUser={msg.isUser}
                                    avatar={msg.avatar ?? undefined}
                                    onClick={() => setActiveMessageIndex(activeMessageIndex === index ? null : index)}
                                    onMenuOption={(option: string) => onSelectMenuOption(msg, index, option)}
                                    isActive={index === activeMessageIndex}
                                    noClick={msg.noClick}
                                    isContextMenuOpen={index === activeMessageIndex}
                                />
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                    </div>
                    {!isFetching &&
                        <div className="fixed bottom-0 w-full max-w-3xl p-4 bg-color7">
                            <ChatInput onSendMessage={handleSendMessage} onSendAudio={handleSendAudio} />
                        </div>
                    }
                    {isUrlDialogOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
                                <h2 className="text-lg font-bold mb-4">Enter Document URL</h2>
                                <input
                                    type="text"
                                    value={inputUrl}
                                    onChange={(e) => setInputUrl(e.target.value)}
                                    className="border p-2 w-full mb-4"
                                    placeholder="Enter PDF URL"
                                />
                                <div className="flex justify-end space-x-2">
                                    <button
                                        className="px-4 py-2 bg-gray-300 rounded"
                                        onClick={() => setIsUrlDialogOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="px-4 py-2 bg-blue-500 text-white rounded"
                                        onClick={() => {
                                            setDocUrl(inputUrl);  // Update the docUrl state
                                            setIsPdfMode(true);   // Switch to PDF mode
                                            setIsUrlDialogOpen(false);  // Close the dialog
                                        }}
                                    >
                                        Open PDF
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
        </>
    );
};

export default ChatWindow;

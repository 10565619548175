import React, { useState } from 'react';
import { TextInput } from '../supported-elements/text-input';
import { FormWrapper } from '../form-wrapper';
import { apiFormCall } from '../../utils/api';
import Spinner from '../../utils/spinner';

interface LoginPanelProps {
    onSuccess: () => void;
}

const LoginPanel: React.FC<LoginPanelProps> = ({ onSuccess }) => {
    const [loginStatus, setLoginStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

    const handleLogin = async (formData: FormData) => {
        try {
            setLoginStatus('loading');
            const result = await apiFormCall('/login', formData as FormData);
            console.log('Login successful:', result);
            setLoginStatus('success');
            onSuccess();
        } catch (error) {
            console.error('Login failed:', error);
            setLoginStatus('error');
        }
    };

    return (
        <div className="text-color12">
            {loginStatus === 'idle' && (
                <FormWrapper onSubmit={handleLogin} submitButtonLabel="Log In">
                    <div>
                        <p>Please enter your username and password to log in:</p>
                        <TextInput name="username" placeholder="Username" />
                        <TextInput name="password" placeholder="Password" type="password" />
                    </div>
                </FormWrapper>
            )}
            {loginStatus === 'loading' && <Spinner message="Logging in..." />}
            {loginStatus === 'success' && <p>You are now logged in.</p>}
            {loginStatus === 'error' && <p>Login failed. Please try again.</p>}
        </div>
    );
};

export default LoginPanel;

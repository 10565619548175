import React from 'react';

interface CommentPanelProps {
    uuid: string;
    userUuid: string;
    username: string;
    comment: string;
    originalLanguage: string;
    createdAt: string;
    commentCount: number;
    status: string;
    onSetContext: (context: string) => void;
}

const CommentPanel: React.FC<CommentPanelProps> = ({
    uuid,
    userUuid,
    username,
    comment,
    originalLanguage,
    createdAt,
    commentCount,
    status,
    onSetContext,
}) => {
    // Format the createdAt date
    const formattedDate = new Date(createdAt).toLocaleString();

    return (
        <div className="comment-panel">
            <div className="flex justify-between items-center mb-2">
                <span className="font-extrabold text-base">Comment</span>
            </div>
            <div className="flex justify-between items-center mb-2">
                <div className="text-sm text-gray-500">
                    <span className="font-semibold text-gray-800">{username}</span>
                    <span className="mx-1">•</span>
                    <span>{formattedDate}</span>
                </div>
            </div>
            <div className="text-gray-800">
                <p>{comment}</p>
            </div>
            <div>
                {commentCount > 0 && (
                    <div className="text-xs text-gray-400 mt-2">
                        {isNaN(commentCount) ? 0 : commentCount} {commentCount === 1 ? 'comment' : 'comments'}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CommentPanel;

// src/components/supported-elements/avatar-control.tsx
import React, { useState } from 'react';
import { FaCamera, FaSpinner } from 'react-icons/fa';
import { apiFormCall } from '../../utils/api'; // Import the apiFormCall function

interface AvatarControlProps {
    existingAvatarUrl?: string; // Optional prop for an existing avatar URL
}

export const AvatarControl: React.FC<AvatarControlProps> = ({ existingAvatarUrl }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(existingAvatarUrl || null); // Use existingAvatarUrl if provided
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadError, setUploadError] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        if (file) {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) return;

        setIsUploading(true);
        setUploadError(null);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await apiFormCall('/upload/avatar', formData);

            if (response.status === 'success') {
                console.log('Avatar uploaded successfully');
                setPreviewUrl(response.avatar_url); // Optionally update the preview with the server URL
            } else {
                throw new Error('Failed to upload avatar');
            }
        } catch (error) {
            setUploadError((error as Error).message);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <>
            <div className="profile-panel text-color12">
            <div className="flex justify-between items-center mb-2">
                <span className="font-extrabold text-base">Update Avatar</span>
            </div>
            </div>
            <div className="flex flex-col items-center">
            <div className="relative w-32 h-32 rounded-full overflow-hidden bg-gray-100 border border-gray-300">
                {previewUrl ? (
                    <img src={previewUrl} alt="Avatar Preview" className="w-full h-full object-cover" />
                ) : (
                    <div className="flex items-center justify-center h-full text-gray-400">
                        <FaCamera size={32} />
                    </div>
                )}
                <input
                    type="file"
                    accept="image/*"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    onChange={handleFileChange}
                />
            </div>
            <button
                onClick={handleUpload}
                className={`mt-4 px-4 py-2 rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none ${isUploading ? 'cursor-not-allowed opacity-50' : ''}`}
                disabled={!selectedFile || isUploading}
            >
                {isUploading ? <FaSpinner className="animate-spin" /> : 'Upload Avatar'}
            </button>
            {uploadError && <p className="text-red-500 mt-2">{uploadError}</p>}
        </div>
        </>
    );
};

import React, { useState, useRef, useEffect } from 'react';
import { PdfLoader, PdfHighlighter, Highlight, Popup } from "react-pdf-highlighter";
import { FaTimes, FaUsers, FaRobot, FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import icons
import Tip from "./tip";  // Assuming Tip is the component for creating highlights
import ChatInput from './chat-input'; // Chat input component (optional)
import AnnotationBox from './AnnotationBox';
import Spinner from "../utils/spinner"; // Spinner component
import { apiCall } from '../utils/api'; // Assuming this is your API utility
import './pdf-viewer.css'; // Custom styles
import logo from '../assets/logo.png'; // Your logo asset

interface ExtendedHighlight {
  id: string;
  position: any;
  content: { text?: string; image?: string };
  comment: { text: string; emoji: string };
  userId: string;
  url: string;
}

interface Avatar {
  userId: string;
  username: string;
}

interface PDFViewerProps {
  initialUrl: string;
  userId: string;
  loadAnnotationView: (params: any) => void;
  exitAnnotationView: () => void;
}

/*
const HighlightPopup = ({
  comment,
}: {
  comment: { text: string; emoji: string };
}) =>
  comment && comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;
*/

const PDFViewer: React.FC<PDFViewerProps> = ({ initialUrl, userId, loadAnnotationView, exitAnnotationView }) => {
  const proxyInitialUrl = `https://api.mostlyhuman.io/pdf-stream?url=${encodeURIComponent(initialUrl)}`;
  const [url, setUrl] = useState<string>(proxyInitialUrl);
  const [highlights, setHighlights] = useState<Array<ExtendedHighlight>>([]);
  const scrollViewerToRef = useRef<(highlight: any) => void>();
  const [showChatInput, setShowChatInput] = useState(false);
  const chatInputRef = useRef<HTMLDivElement>(null);
  const [showingAvatars, setShowingAvatars] = useState(false);
  const [currentUsers, setCurrentUsers] = useState<Avatar[]>([]);
  const setSelectedAvatar = useState<Avatar | null>(null)[1];
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [currentPage, setCurrentPage] = useState(0);
  const avatarsPerPage = 8;
  const [selectedAnnotation, setSelectedAnnotation] = useState<any>(null);
  const avatarMenuRef = useRef<HTMLDivElement>(null);


  // WebSocket presence tracking
  useEffect(() => {
    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    const ws = new WebSocket(`${protocol}://api.mostlyhuman.io/ws/document/1234/presence`);

    ws.onopen = () => ws.send(JSON.stringify({ action: "ping" }));
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.action === "presence") {
        setCurrentUsers(message.users);  // Update current users
      }
    };

    return () => ws.close();
  }, [initialUrl]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (avatarMenuRef.current && !avatarMenuRef.current.contains(event.target as Node)) {
        setShowAvatarMenu(false); // Close the avatar menu if clicked outside
      }
    }

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [avatarMenuRef]);

  // Function to add a new highlight (simulating adding annotation)
  const addHighlight = async (highlight: ExtendedHighlight) => {
    setHighlights((currentHighlights) => [highlight, ...currentHighlights]);
    // Persist the highlight
    await apiCall('/annotations', highlight, 'POST');
  };

  const handleReply = () => {
    console.log('Replying to comment');
    setSelectedAnnotation(null);
  };

  useEffect(() => {
    // Function to fetch annotations
    const getContentAnnotations = async () => {
      try {
        let response = await apiCall(`/annotations?url=${encodeURIComponent(initialUrl)}`, null, 'GET');
        if (response?.annotations && Array.isArray(response.annotations)) {
          const newHighlights: any[] = response.annotations.map((annotation: any) => ({
            ...annotation,
            position: typeof annotation.positionData === 'string' ? JSON.parse(annotation.positionData) : annotation.positionData
          }));
          setHighlights(newHighlights);
        }
      } catch (error) {
        console.error('Error fetching annotations:', error);
      }
    };

    const newProxyUrl = `https://api.mostlyhuman.io/pdf-stream?url=${encodeURIComponent(initialUrl)}`;
    setUrl(newProxyUrl); // Update the URL with new PDF
    getContentAnnotations(); // Fetch annotations for the new PDF
  }, [initialUrl]);

  // Avatar-related functions
  const computeAvatarUrl = (userId: string) => `https://mostlyhuman-public.s3.amazonaws.com/avatars-${userId}`;

  const handleAvatarClick = (avatar: Avatar, event: React.MouseEvent) => {
    setSelectedAvatar(avatar);

    // Typecast event.target to HTMLElement
    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();
    setMenuPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
    setShowAvatarMenu(true);
  };

  const renderAvatars = () => {
    const start = currentPage * avatarsPerPage;
    const avatarsToShow = currentUsers.slice(start, start + avatarsPerPage);

    return (
      <div className="flex items-center">
        <FaChevronLeft onClick={prevPage} size={32} className="cursor-pointer" />
        <div className="flex overflow-x-auto space-x-2">
          {avatarsToShow.map(user => (
            <img
              key={user.userId}
              src={computeAvatarUrl(user.userId)}
              alt="Avatar"
              className="w-12 h-12 rounded-full cursor-pointer"
              onClick={(e) => handleAvatarClick(user, e)}
            />
          ))}
        </div>
        <FaChevronRight onClick={nextPage} size={32} className="cursor-pointer" />
      </div>
    );
  };

  const nextPage = () => {
    if ((currentPage + 1) * avatarsPerPage < currentUsers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Avatar Action Menu
  const renderAvatarActionMenu = () => (
    <div
      className="Avatar__menu"
      ref={avatarMenuRef}
      style={{ top: menuPosition.top, left: menuPosition.left, position: 'absolute', zIndex: 1000 }}
    >
      <button className="Avatar__menu-option" onClick={() => console.log('View Profile')}>View Profile</button>
      <button className="Avatar__menu-option" onClick={() => console.log('Send Message')}>Send Message</button>
      <button className="Avatar__menu-option" onClick={() => console.log('Chat with Avatar')}>Chat with Avatar</button>
      <button className="Avatar__menu-option" onClick={() => console.log('Send AI Chat Message')}>Send AI Chat Message</button>
    </div>
  );

  return (
    <div className="App" style={{ display: "flex", height: "100vh" }}>
      {/* Floating Toolbar */}
      <div className="fixed top-4 left-4 z-10 flex flex-col gap-2 p-2 bg-white shadow-md rounded">
        <img src={logo} height={32} width={32} className="pb-2 border-b-4 border-[#484848]" alt="logo" />
        <button onClick={exitAnnotationView} className="text-color5 hover:text-color3">
          <FaTimes size={32} />
        </button>
        <button onClick={() => setShowingAvatars(!showingAvatars)} className="text-color5 hover:text-color3">
          <FaUsers size={32} />
        </button>
        <button onClick={() => setShowChatInput(!showChatInput)} className="text-color5 hover:text-color3">
          <FaRobot size={32} />
        </button>
      </div>

      {/* Avatar Strip */}
      {showingAvatars && currentUsers.length > 0 && (
        <div className="absolute top-4 left-20 z-20 bg-white p-2 rounded shadow-md">
          {renderAvatars()}
        </div>
      )}

      {/* Avatar Action Menu */}
      {showAvatarMenu && renderAvatarActionMenu()}

      {/* ChatInput */}
      {showChatInput && (
        <div
          className="fixed bottom-4 left-20 right-20 z-20 bg-white p-4 rounded shadow-md"
          ref={chatInputRef}
        >
          <ChatInput
            onSendMessage={(message) => console.log("Message sent:", message)}
            onSendAudio={(audioFile) => console.log("Audio file sent:", audioFile)}
          />
        </div>
      )}

      {/* PDF Viewer */}
      <div style={{ height: "100vh", width: "100vw", position: "relative" }}>
        <PdfLoader url={url} beforeLoad={<Spinner />}>
          {(pdfDocument) => (
            <PdfHighlighter
              pdfDocument={pdfDocument}
              onScrollChange={() => { }}
              scrollRef={(scrollTo) => { scrollViewerToRef.current = scrollTo; }}
              onSelectionFinished={(position, content, hideTipAndSelection, transformSelection) => (
                <Tip
                  onOpen={transformSelection}
                  onConfirm={(comment) => {
                    addHighlight({ content, position, comment, id: String(Math.random()), userId, url: initialUrl });
                    hideTipAndSelection();
                  }}
                />
              )}
              highlightTransform={(highlight, index, setTip, hideTip, viewportToScaled, screenshot, isScrolledTo) => {
                // Safely access comment.emoji and comment.text
                const commentText = highlight?.comment?.text || '';
                const commentEmoji = highlight?.comment?.emoji || '';

                return (
                  <Popup
                    popupContent={<div>{commentEmoji} {commentText}</div>}
                    onMouseOver={(popupContent) => setTip(highlight, () => popupContent)}
                    onMouseOut={hideTip}
                    key={index}
                    children={
                      <Highlight
                        isScrolledTo={isScrolledTo}
                        position={highlight.position}
                        comment={highlight.comment}
                        onClick={() => {
                          // Set the full highlight object to selectedAnnotation
                          setSelectedAnnotation(highlight);
                        }}
                      />
                    }
                  />
                );
              }}
              highlights={highlights}
              enableAreaSelection={() => false}  // Disable area selection
            />
          )}
        </PdfLoader>

        {/* Annotation Box */}
        {selectedAnnotation && (
          <div
            className="Annotation__box-wrapper"
            style={{
              position: 'absolute',
              top: selectedAnnotation.position.boundingRect.y1 + 20,
              left: selectedAnnotation.position.boundingRect.x1 + 20,
            }}
          >
            <AnnotationBox
              userId={selectedAnnotation.user.uuid}
              username={selectedAnnotation.user.name || 'Anonymous'}
              date={selectedAnnotation.createdAt}
              comment={selectedAnnotation.commentText}
              onReply={handleReply}
              onClose={() => setSelectedAnnotation(null)}
            />
          </div>
        )}
      </div>

      {/* ChatInput */}
      {showChatInput && (
        <div
          className="fixed bottom-4 left-20 right-20 z-20 bg-white p-4 rounded shadow-md"
          ref={chatInputRef}
        >
          <ChatInput
            onSendMessage={(message) => console.log("Message sent:", message)}
            onSendAudio={(audioFile) => console.log("Audio file sent:", audioFile)}
          />
        </div>
      )}
    </div>
  );
};

export default PDFViewer;

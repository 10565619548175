import React, { useState } from 'react';
import { FaShareSquare } from 'react-icons/fa';
import { apiCall } from '../../utils/api'; // Import the apiCall function
import Spinner from '../../utils/spinner';

interface DiscussionPanelProps {
    uuid: string;
    userUuid: string;
    topic: string;
    commentCount: number;
    originalLanguage: string;
    hashtags: string[];
    accessConstraints: string[];
    status: string;
    onReply: (uuid: string) => void;
    onSetContext: (context: string) => void;
    onShared: () => void;
}

const DiscussionPanel: React.FC<DiscussionPanelProps> = ({
    uuid,
    userUuid,
    topic,
    commentCount,
    originalLanguage,
    hashtags,
    accessConstraints,
    status,
    onReply,
    onSetContext,
    onShared
}) => {
    const showReplies = useState(false);
    const replies = useState(0);
    const [forwardText, setForwardText] = useState(status === 'shared' ? 'This discussion has been shared with the community.' : 'Click to open this discussion to the community.');
    const [forwarding, setForwarding] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(status);

    const logState = (msg: string) => {
        const state = {
            showReplies,
            replies,
            forwardText,
            forwarding
        }
        console.log(msg);
        console.log(`discussion panel state: ${JSON.stringify(state)}`);
    }

    const handleForwardClick = async () => {
        logState("ENTER handleForwardClick");
        console.log(`handleForwardClick --> currentStatus: {currentStatus}; forwarding: ${forwarding}`)
        if (currentStatus === 'shared') return;

        if (forwarding) {
            setIsSubmitting(true);
            const result = await apiCall('/share-discussion', { user_intent_uuid: uuid, additional_input: '' });
            console.log('Forward successful:', result);
            setForwardText('This discussion has been shared with the community. Select this message to respond to reply to the discussion thread.');
            setCurrentStatus('shared');
            setForwarding(false);
            onSetContext('');
            setIsSubmitting(false);
            onShared();
        } else {
            setForwardText('Click to forward to all members, or describe the conditions below for receiving this question.');
            onSetContext('forward');
            setForwarding(true);
        }
        logState("EXIT handleForwardClick");
    };

    return (
        <div className="discussion-panel text-color12">
            {isSubmitting ? (
                <Spinner message="Submitting your response..." />
            ) : (
                <>
                    <div className="flex justify-between items-center mb-2">
                        <span className="font-extrabold text-base">Discussion Topic</span>
                    </div>
                    <p>{topic}</p>
                    <div className="mt-2 text-blue-500 flex items-center" onClick={handleForwardClick}>
                        <span className="text-bolder text-sm flex-1">{forwardText}</span>
                        {currentStatus !== 'shared' && forwardText !== 'Forwarded successfully!' && <FaShareSquare className="ml-2 flex-none" />}
                    </div>
                    <div className="flex justify-between items-center mt-4">
                        <div className="flex items-center font-bold text-sm text-gray-300">
                            <span className="mr-2">{isNaN(commentCount) ? 0 : commentCount} {commentCount === 1 ? 'comment' : 'comments'}</span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default DiscussionPanel;

import React from 'react';

interface ButtonProps {
    label: string;
    onClick: () => void;
    className?: string;
}

export const Button: React.FC<ButtonProps> = ({ label, onClick, className = "" }) => {
    return (
        <button
            onClick={onClick}
            className={`p-2 bg-color12 text-color10 rounded-xl mt-2 ${className}`}
        >
            {label}
        </button>
    );
};

// search-result.tsx
import React from 'react';

interface SearchResultPanelProps {
    uuid: string;
    content: string;
    type: string;
    onSelect: (uuid: string, content: string, type: string) => void;
}

const SearchResultPanel: React.FC<SearchResultPanelProps> = ({
    uuid,
    content,
    type,
    onSelect,
}) => {

    return (
        <div 
            className="user-reply-panel text-color12 cursor-pointer"
            onClick={() => onSelect(uuid, content, type)}
        >
            <p className="font-bold mb-2">{type}</p>
            <p>{content}</p>
        </div>
    );
};

export default SearchResultPanel;

import React, { useState } from 'react';

interface MessageBubbleProps {
    message: string | React.ReactNode;
    isUser: boolean;
    avatar?: string;
    onClick: () => void;
    onMenuOption: (option: string) => void;
    isActive: boolean;
    isContextMenuOpen: boolean;  // New prop
    noClick: boolean | undefined;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ message, isUser, avatar, onClick, onMenuOption, isActive, isContextMenuOpen, noClick = false }) => {
    const [loaded, setLoaded] = useState(false);
    const defaultUrl: string = 'https://mostlyhuman-public.s3.us-east-1.amazonaws.com/mh-default-avatar.png';
    const loadImage = async (url: string) => {
        try {
            let response = await fetch(url);
            if (!response.ok) {
                //response = await fetch(defaultUrl);
                return defaultUrl;
            }
            return url;
        } catch (error) {
            //await fetch(defaultUrl);
            return defaultUrl;
        }
    };
    return (
        <div
            onClick={() => { if (!noClick) { onClick() } }}
            className={`flex flex-col items-start max-w-[90%] my-2 ${isUser ? 'ml-auto justify-end' : 'mr-auto justify-start'} text-base ${noClick ? '' : 'cursor-pointer'}`}
        >
            <div className="flex items-center w-full">
                {isUser && avatar && (
                    <img
                        src={defaultUrl}
                        alt="Avatar"
                        className={`mr-4 w-12 h-12 rounded-full bg-color10 ${isActive ? 'border-2 border-blue-500' : ''}`}
                        onLoad={async (e) => {
                            if (!loaded) {
                                console.log(`onLoad...`)
                                const image = e.target as HTMLImageElement;
                                const newSrc = await loadImage(avatar);
                                console.log(`oldSrc: ${image.src}; newSrc: ${newSrc}`)
                                if (image.src !== newSrc) {
                                    console.log(`swappin'`)
                                    image.src = newSrc;
                                }
                                setLoaded(true);
                            }
                        }}
                    />
                )}
                <div
                    className={`p-3 rounded-lg ${isUser ? 'bg-color9' : 'bg-color10'} shadow-md ${isActive ? 'border-2 border-blue-500' : ''}`}
                >
                    {typeof message === 'string' ? <p className="text-color12">{message}</p> : message}

                    {/* Context Menu */}
                    <div
                        className={`overflow-hidden transition-max-height duration-750 ease-in-out ${isContextMenuOpen ? 'max-h-40' : 'max-h-0'} w-full`}
                    >
                        {/* Separator */}
                        <div className="w-full border-t border-gray-300 my-2"></div>
                        <div className="p-2 bg-white rounded-lg shadow-lg border border-gray-300">
                            <p
                                className="font-semibold text-blue-500 cursor-pointer"
                                onClick={() => onMenuOption('see-details')}
                            >
                                See details
                            </p>
                            <p className="mt-2 text-sm text-gray-700">This item has been selected. You can type below or send a voice message to reply or leave feedback.</p>
                        </div>
                    </div>
                </div>
                {!isUser && avatar && (
                    <img
                        src={avatar}
                        alt="Avatar"
                        className={`ml-4 w-12 h-12 rounded-full bg-color10 ${isActive ? 'border-2 border-blue-500' : ''}`}
                        onLoad={async (e) => {
                            if (!loaded) {
                                console.log(`onLoad...`)
                                const image = e.target as HTMLImageElement;
                                const newSrc = await loadImage(avatar);
                                if (image.src !== newSrc) {
                                    image.src = newSrc;
                                }
                                setLoaded(true);
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default MessageBubble;

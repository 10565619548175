import React, { useState } from 'react';
import { FaShareSquare, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { apiCall } from '../../utils/api';
import Spinner from '../../utils/spinner';

interface SurveyPanelProps {
  uuid: string;
  userUuid: string;
  prompt: string;
  language: string;
  choices: string[]; // Choices for multiple-choice questions
  allowMultipleChoice: boolean; // Whether multiple choices are allowed
  freetext: boolean; // Whether free text input is allowed
  rating: boolean; // Whether the question is a rating question
  ranking: boolean; // Whether the question is a ranking question
  hashtags: string[];
  commentCount: number;
  accessConstraints: string[];
  status: string;
  userAnswer?: any; // Optional field to display user's previous answer
  onReply: (uuid: string) => void;
  onSetContext: (context: string) => void;
  onShared: () => void;
}

const SurveyPanel: React.FC<SurveyPanelProps> = ({
  uuid,
  userUuid,
  prompt,
  language,
  choices,
  allowMultipleChoice,
  freetext,
  rating,
  ranking,
  hashtags,
  commentCount,
  accessConstraints,
  status,
  userAnswer,
  onReply,
  onSetContext,
  onShared
}) => {
  const showReplies = useState(false);
  const replies = useState(0);
  const [forwardText, setForwardText] = useState(status === 'shared' ? 'This survey question has been shared with the community.' : 'Would you like to share and get answers from the community?');
  const [forwarding, setForwarding] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle submission status
  const [freeText, setFreeText] = useState(userAnswer?.free_text || '');
  const [selectedChoices, setSelectedChoices] = useState<string[]>(userAnswer?.choices || []);
  const [ratingValue, setRatingValue] = useState<number | null>(userAnswer?.rating || null);
  const [rankingValues, setRankingValues] = useState<string[]>(userAnswer?.ranking || choices || []);
  const [currentStatus, setCurrentStatus] = useState(status);

  const logState = (msg: string) => {
    const state = {
      showReplies,
      replies,
      forwardText,
      forwarding,
      isSubmitting,
      freeText,
      selectedChoices,
      ratingValue,
      rankingValues
    };
    console.log(msg);
    console.log(`survey panel state: ${JSON.stringify(state)}`);
  };

  const handleForwardClick = async () => {
    logState('ENTER handleForwardClick');
    if (currentStatus === 'shared') return;

    if (forwarding) {
      setIsSubmitting(true);
      const result = await apiCall('/share-survey-question', { user_intent_uuid: uuid, additional_input: '' });
      console.log('Forward successful:', result);
      setForwardText('This survey question has been shared with the community.');
      setCurrentStatus('shared');
      setForwarding(false);
      onSetContext('');
      setIsSubmitting(false);
      onShared();
    } else {
      setForwardText('Click to forward to all members, or describe the conditions below for receiving this question.');
      onSetContext('forward');
      setForwarding(true);
    }
    logState('EXIT handleForwardClick');
  };

  const handleSubmit = async () => {
    logState('ENTER handleSubmit');
    setIsSubmitting(true);
    try {
      // Gather responses
      const response = {
        survey_question_uuid: uuid,
        user_uuid: userUuid,
        free_text: freetext ? freeText : undefined,
        choices: selectedChoices.length > 0 ? selectedChoices : undefined,
        rating: ratingValue,
        ranking: ranking ? rankingValues : undefined,
        timestamp: new Date().toISOString(),
      };

      // Submit the response
      await apiCall('/submit-survey-response', response);
      console.log('Survey response submitted:', response);
      setCurrentStatus('answered');
      onShared();
      // Update UI to reflect submission
      // Assuming the backend returns the updated UserIntent, you can use it to update the state
    } catch (error) {
      console.error('Error submitting survey response:', error);
    } finally {
      setIsSubmitting(false);
    }
    logState('EXIT handleSubmit');
  };

  const handleOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedChoices([...selectedChoices, 'Other']);
    } else {
      setSelectedChoices(selectedChoices.filter(choice => choice !== 'Other'));
      setFreeText('');
    }
  };

  const moveChoice = (index: number, direction: 'up' | 'down') => {
    setRankingValues(prev => {
      const newValues = [...prev];
      if (direction === 'up' && index > 0) {
        [newValues[index - 1], newValues[index]] = [newValues[index], newValues[index - 1]];
      } else if (direction === 'down' && index < newValues.length - 1) {
        [newValues[index + 1], newValues[index]] = [newValues[index], newValues[index + 1]];
      }
      return newValues;
    });
  };

  console.log(`ranking: ${ranking}; rankingValues: ${rankingValues}; choices.length: ${choices.length}`)
  console.log(`answer: ${currentStatus === 'answered' || (choices.length === 0 && !selectedChoices.includes('Other'))}`)

  return (
    <div className="survey-panel text-color12">
      {isSubmitting ? (
        <Spinner message="Submitting your response..." />
      ) : (
        <>
          <div className="flex justify-between items-center mb-2">
            <span className="font-extrabold text-base">Survey Question</span>
          </div>
          <p className="font-bold mb-2">{prompt}</p>
          {(!ranking && !rankingValues) && choices && choices.length > 0 && (
            <div className="mt-2">
              {choices.map((choice, index) => (
                <div key={index} className="flex flex-start items-center">
                  <input
                    type={allowMultipleChoice ? 'checkbox' : 'radio'}
                    id={`choice-${index}`}
                    name={`survey-${uuid}`}
                    value={choice}
                    className="mr-1 bg-color7"
                    onChange={(e) => {
                      if (allowMultipleChoice) {
                        setSelectedChoices(
                          e.target.checked
                            ? [...selectedChoices, choice]
                            : selectedChoices.filter(c => c !== choice)
                        );
                      } else {
                        setSelectedChoices([choice]);
                      }
                    }}
                    checked={selectedChoices.includes(choice)}
                    disabled={currentStatus === 'answered'}
                  />
                  <label htmlFor={`choice-${index}`}>{choice}</label>
                </div>
              ))}
              {!choices.includes('Other') && freetext && (
                <div className="flex flex-start">
                  <input
                    type={allowMultipleChoice ? 'checkbox' : 'radio'}
                    id="other-choice"
                    name={`survey-${uuid}`}
                    value="Other"
                    className="mr-1 bg-color7"
                    onChange={handleOtherChange}
                    checked={selectedChoices.includes('Other')}
                    disabled={currentStatus === 'answered'}
                  />
                  <label htmlFor="other-choice">Other</label>
                </div>
              )}
            </div>
          )}
          {(freetext || !selectedChoices.includes('Other')) && (
            <div className="mt-2">
              <div><label htmlFor="freetext-response">Your response:</label></div>
              <textarea
                id="freetext-response"
                name="freetext-response"
                rows={4}
                value={freeText}
                onChange={(e) => setFreeText(e.target.value)}
                disabled={currentStatus === 'answered' || (choices.length > 0 && !selectedChoices.includes('Other'))}
                placeholder="Specify other"
                className="bg-color7 w-full p-2 border rounded-lg"
              ></textarea>
            </div>
          )}
          {!ranking && (rating || ratingValue) && (
            <div className="mt-2">
              <input
                type="range"
                id="rating"
                name="rating"
                min="1"
                max="5"
                value={ratingValue || ''}
                onChange={(e) => setRatingValue(Number(e.target.value))}
                disabled={currentStatus === 'answered'}
                className="bg-color7"
              />
            </div>
          )}
          {(ranking || rankingValues) && (
            <div className="mt-2">
              {rankingValues.map((choice, index) => (
                <div key={index} className="flex items-center justify-between mb-2">
                  <span>{choice}</span>
                  <div className="flex items-center">
                    <button
                      type="button"
                      onClick={() => moveChoice(index, 'up')}
                      disabled={index === 0 || currentStatus === 'answered'}
                      className={`mr-2 ${index === 0 || currentStatus === 'answered' ? 'text-gray-400' : 'text-gray-600 hover:text-gray-800'}`}
                    >
                      <FaChevronUp />
                    </button>
                    <button
                      type="button"
                      onClick={() => moveChoice(index, 'down')}
                      disabled={index === rankingValues.length - 1 || currentStatus === 'answered'}
                      className={`${index === rankingValues.length - 1 || currentStatus === 'answered' ? 'text-gray-400' : 'text-gray-600 hover:text-gray-800'}`}
                    >
                      <FaChevronDown />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-center mt-4">
            <button
              className={`py-1 px-2 rounded-xl ${currentStatus === 'answered' || isSubmitting ? 'bg-gray-300 text-white cursor-not-allowed' : 'bg-color12 hover:bg-blue-600 text-white'}`}
              onClick={handleSubmit}
              disabled={currentStatus === 'answered' || isSubmitting}
            >
              Submit
            </button>
          </div>
          <div className="mt-2 text-blue-500 flex items-center" onClick={handleForwardClick}>
            <span className="text-bolder text-sm flex-1">{currentStatus !== 'answered' ? forwardText : 'This was your answer.'}</span>
            {currentStatus !== 'shared' && currentStatus !== 'answered' && <FaShareSquare className="ml-2 flex-none" />}
          </div>
          <div className="flex justify-between items-center mt-4">
            <div className="flex items-center font-bold text-sm text-gray-300">
              <span className="mr-2">{isNaN(commentCount) ? 0 : commentCount} {commentCount === 1 ? 'comment' : 'comments'}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SurveyPanel;

// utils/spinner.tsx
import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import './spinner.css'; // Import the CSS file for spinner styles

interface SpinnerProps {
    message?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ message }) => {
    return (
        <div className="spinner-container">
            <FaSpinner className="spinner" />
            {message && <p>{message}</p>}
        </div>
    );
};

export default Spinner;
